<template>
  <div class="new-document-container">
    <form @submit.prevent="handleSubmit">
      <div class="new-document">
        <div class="columns">
          <div class="column is-12 form-group relative">
            <p>Company<sup class="display-flex required">*</sup></p>
            <v-select
              label="companyName"
              placeholder="Type to search.."
              :options="localCompanyList"
              :loading="loadingSearchedCompanies"
              @search="handleCompanySearch"
              @input="onChangeCompanyName"
              :clearSearchOnSelect="true"
              :value="dataSource.selectedCompany"
              :filterable="false"
              :required="true"
            >
              <template slot="no-options">
                Type to search..
              </template></v-select
            >
            <span v-if="isValidCompanyName" class="required-error"
              >Company is required</span
            >
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <input-vue
              :value="dataSource.documentTitle"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeTitle"
              label="Document title"
            />
          </div>
          <div class="column is-6">
            <input-vue
              :required="true"
              :value="dataSource.documentUrl"
              :min="1"
              type="url"
              :submitted="submitted"
              @onChangeName="onChangeDocUrl"
              label="Document URL"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column is-6 form-group relative">
            <p>Document Category<sup class="display-flex required">*</sup></p>
            <v-select
              label="type"
              placeholder="Type to search.."
              :options="localDocCategories"
              :loading="loadingDocCategories"
              @search="handleDocCategorySearch"
              @input="onChangeCategorySearch"
              :clearSearchOnSelect="true"
              :value="dataSource.selectedDocCategory"
              :filterable="false"
            >
              <template slot="no-options">
                Type to search..
              </template></v-select
            >
            <span v-if="isValidCategory" class="required-error"
              >Document Category is required</span
            >
          </div>
          <div class="column is-6 form-group relative">
            <p>Document Format<sup class="display-flex required">*</sup></p>
            <v-select
              :value="dataSource.selectedDocType"
              v-model="dataSource.selectedDocType"
              :options="docType"
              @input="onChangeDocType"
            ></v-select>
            <span v-if="isValidDocType" class="required-error"
              >Document Format is required</span
            >
          </div>
        </div>
        <div class="columns">
          <div class="column is-6 form-group relative">
            <p>Document Language</p>
            <v-select
              v-model="dataSource.selectedlanguage"
              :options="languages"
              :value="dataSource.selectedlanguage"
              @input="onChangeLanguage"
            ></v-select>
          </div>
          <div class="column is-6">
            <div class="form-group">
              <p>Published Date<sup class="display-flex required">*</sup></p>
              <b-datepicker
                icon-right-clickable
                @icon-right-click="clearDate"
                icon-right="close-circle"
                :placeholder="dataSource.foundedDatePlaceHolder"
                class="form-control"
                v-model="dataSource.creationDate"
                icon="calendar-today"
                @change-year="handleYearChange"
                @change-month="handleMonthChange"
                :unselectable-dates="noFutureDates"
                trap-focus
                :years-range="[-100, 0]"
              >
              </b-datepicker>
              <span
                v-if="!this.dataSource.creationDate && isValidDate"
                class="required-error"
                >Published Date is required</span
              >
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <div>
                <div>
                  <UploadVue
                    ref="uploadRef"
                    label="Upload Document"
                    @onChangeFile="handleFileUpload"
                  ></UploadVue>
                </div>
                <div v-if="documentFile.name" :style="'position: absolute; '">
                  <div class="delete-icon">
                    <p :style="'margin-top: 4px'">{{ documentFile.name }}</p>
                    <div @click="handleCancelUpload" class="deleteBtn">
                      <b-icon icon="delete"> </b-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="actions">
                <b-button @click.prevent="onChangeModal(false)">Close</b-button>
                <b-button @click.prevent="handleSubmit" :loading="isLoading"
                  >Add</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import InputVue from '../../components/PMT/Input.vue'
import DropdownWrapVue from '../../components/Common/DropdownWrap.vue'
import CommonSelectionVue from '../../components/Dropdowns/CommonSelection.vue'
import UploadVue from '../../components/PMT/Upload.vue'
import { debounce } from '../../util/util'
import dataSources from '../../store/admin/dataSources'
import { Loader } from '@progress/kendo-vue-indicators'

/** This component contains the code to add a new document to the database */
export default {
  name: 'AddDocument',
  components: {
    InputVue,
    DropdownWrapVue,
    CommonSelectionVue,
    UploadVue,
    loader: Loader
  },
  props: {
    onChangeModal: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      dataSource: {
        dataSourceName: null,
        documentTitle: null,
        documentUrl: null,
        publicationTimestamp: null,
        moduleId: [],
        selectedCompany: {
          companyId: null,
          companyName: null
        },
        selectedDocCategory: {
          id: null,
          type: null
        },
        selectedlanguage: null,
        selectedlanguageValue: null,
        selectedDocType: null,
        selectedDocValue: null,
        foundedDefaultText: 'Select Date',
        foundedDatePlaceHolder: 'Select Date',
        creationYear: null,
        creationMonth: null,
        creationDate: null,
        s3Path: null,
        loadingUpload: false
      },
      isValidCompanyName: false,
      isValidUrl: false,
      isValidTitle: false,
      isValidCategory: false,
      isValidDocType: false,
      isValidLanguage: false,
      isValidDate: false,
      isValidFormat: false,
      languages: [{ label: 'English', value: 'en' }],
      docType: [
        { label: 'PDF', value: 'application/pdf' },
        { label: 'Webpage', value: 'application/webpage' },
        { label: 'MS Excel', value: 'application/msexcel' },
        { label: 'Other', value: 'other' }
      ],
      openModule: false,
      localCompanyList: [],
      localDocCategories: [],
      localDocFormat: [],
      loadingSearchedCompanies: false,
      loadingDocCategories: false,
      loadingDocFormat: false,
      submitted: false,
      selectedDocFormat: {
        docFormatId: null,
        docFormatName: null
      },
      documentFile: {
        file: '',
        name: null
      }
    }
  },
  computed: {
    ...mapState('modules', ['moduleList']),
    ...mapState('pmtDocuments', ['s3UrlPath']),
    ...mapState('filters', ['loading'])
  },
  watch: {
    s3UrlPath(path) {
      this.dataSource.s3Path = path
    }
  },
  mounted() {
    this.getModuleList()
  },
  methods: {
    ...mapActions('dataSource', ['addDataSource']),
    ...mapActions('modules', ['getModuleList']),
    ...mapActions('pmtDocuments', [
      'getDocumentCategoryList',
      'uploadFileToS3',
      'addNewDocument',
      'getPmtDocumentsList',
      'deleteFileFromS3',
      'getCompanyList'
    ]),

    /**
     * This function is a handler function to handel the click on the clear icon on the calendar for founded date
     * This function sets the variable used to save the founded date info to null
     */
    clearDate() {
      this.dataSource.creationDate = null
      this.dataSource.creationYear = null
      this.dataSource.creationMonth = null
      this.dataSource.foundedDatePlaceHolder =
        this.dataSource.foundedDefaultText
      this.isValidDate = false
    },

    /**
     * This function is a handler function to handel the change in the year in the founded date field
     * @param year {number} - selected year
     */
    handleYearChange(year) {
      this.dataSource.creationYear =
        year <= new Date().getFullYear() ? year : null
      if (this.dataSource.creationMonth) {
        this.dataSource.foundedDatePlaceHolder =
          year <= new Date().getFullYear()
            ? this.getMonth(this.dataSource.creationMonth) +
              '-' +
              this.dataSource.creationYear
            : null
      } else {
        this.dataSource.foundedDatePlaceHolder =
          year <= new Date().getFullYear()
            ? year
            : this.dataSource.foundedDefaultText
      }
    },

    /**
     * This function is a handler function to handel the change in the month in the founded date field
     * @param month {number} - selected month
     */
    handleMonthChange(month) {
      this.dataSource.creationMonth = month
      if (this.dataSource.creationYear) {
        this.dataSource.foundedDatePlaceHolder =
          this.getMonth(this.dataSource.creationMonth) +
          '-' +
          this.dataSource.creationYear
      }
    },

    /**
     * This is a filter function to check if the date is a future date or not
     * @param date {Date} - calendar date
     */
    noFutureDates(date) {
      const now = new Date()
      return date >= now
    },
    /**
     * This is a helper function to get a month number starting from 1 instead of 0 from creationMonth
     */
    getMonth(month) {
      return month + 1
    },

    handleCompanySearch(data) {
      if (data) {
        this.loadingSearchedCompanies = true
      }
      this.debounceCompanySearch(data)
    },
    debounceCompanySearch: debounce(async function (data) {
      if (data.length) {
        this.localCompanyList = await this.getCompanyList(data)
      }
      this.loadingSearchedCompanies = false
    }, 800),
    onChangeCompanyName(company) {
      if (company) {
        this.dataSource.selectedCompany = company
        this.isValidCompanyName = false
      } else {
        this.dataSource.selectedCompany = {
          companyId: null,
          companyName: null
        }
        this.isValidCompanyName = true
      }
    },
    handleDocCategorySearch(data) {
      if (data) {
        this.loadingDocCategories = true
      }
      this.debounceCategorySearch(data)
    },

    debounceCategorySearch: debounce(async function (data) {
      if (data.length) {
        this.localDocCategories = await this.getDocumentCategoryList(data)
      }
      this.loadingDocCategories = false
    }, 800),
    onChangeCategorySearch(docCategory) {
      if (docCategory) {
        this.dataSource.selectedDocCategory = docCategory
        this.isValidCategory = false
      } else {
        this.dataSource.selectedDocCategory = {
          docCatId: null,
          docCatName: null
        }
        this.isValidCategory = true
      }
    },

    handleDocFormatSearch(data) {
      if (data) {
        this.loadingDocFormat = true
      }
      this.debounceDocFormatSearch(data)
    },
    debounceDocFormatSearch: debounce(async function (data) {
      if (data.length) {
        this.localDocFormat = await this.getDocFormatList(data)
      }
      this.loadingDocFormat = false
    }, 800),

    onChangeTitle(title, isValid) {
      this.dataSource.documentTitle = title
      this.isValidTitle = isValid
    },
    onChangeDocUrl(url, isValid) {
      this.dataSource.documentUrl = url
      this.isValidUrl = isValid
    },
    onChangeDocType(docType) {
      if (docType) {
        this.dataSource.selectedDocType = docType.label
        this.dataSource.selectedDocValue = docType.value

        this.isValidDocType = false
      } else {
        this.isValidDocType = true
      }
    },
    onChangeLanguage(languages) {
      if (languages) {
        this.dataSource.selectedlanguage = languages.label
        this.dataSource.selectedlanguageValue = languages.value
        this.isValidLanguage = false
      } else {
        this.isValidLanguage = true
      }
    },
    onFocusModule() {
      this.$refs.modules.$el.firstChild.focus()
      this.openModule = true
    },
    onChangeModuleId(moduleId, isValid) {
      this.dataSource.moduleId = moduleId
    },
    onClickOutsideModule() {
      this.openModule = false
    },
    onRemoveModuleName(object) {
      this.dataSource.moduleId = this.dataSource.moduleId.filter(
        (id) => id.module_id !== object.module_id
      )
    },

    handleFileUpload(file) {
      this.documentFile = file
    },

    async handleSubmit() {
      this.submitted = true
      if (
        this.dataSource.selectedCompany &&
        this.dataSource.selectedCompany.companyName
      ) {
        this.isValidCompanyName = false
      } else {
        this.isValidCompanyName = true
      }
      if (
        this.dataSource.selectedDocCategory &&
        this.dataSource.selectedDocCategory.type
      ) {
        this.isValidCategory = false
      } else {
        this.isValidCategory = true
      }
      if (this.dataSource.selectedDocType) {
        this.isValidDocType = false
      } else {
        this.isValidDocType = true
      }
      if (this.dataSource.creationDate) {
        this.isValidDate = false
      } else {
        this.isValidDate = true
      }
      if (this.dataSource.selectedlanguage) {
        this.isValidLanguage = false
      } else {
        this.isValidLanguage = true
      }
      const isFormValid =
        this.dataSource.selectedCompany &&
        this.dataSource.selectedCompany.companyName &&
        this.dataSource.documentUrl &&
        this.dataSource.selectedDocCategory &&
        this.dataSource.selectedDocCategory.type &&
        this.dataSource.selectedDocType &&
        this.dataSource.creationDate
          ? true
          : false

      if (this.dataSource.creationDate) {
        const dateWithCurrentTime = new Date(this.dataSource.creationDate)
        const currentTime = new Date()
        dateWithCurrentTime.setHours(
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds(),
          currentTime.getMilliseconds()
        )
        this.dataSource.creationDate = dateWithCurrentTime
      }

      if (isFormValid) {
        if (this.documentFile.name) {
          this.isLoading = true
          this.uploadFileToS3({
            ...this.documentFile,
            companyId: this.dataSource?.selectedCompany.companyId
          })
            .then((path) => {
              const payload = {
                companyId: this.dataSource?.selectedCompany.companyId,
                documentTitle: this.dataSource.documentTitle,
                documentUrl: this.dataSource.documentUrl,
                docCategoryId: this.dataSource.selectedDocCategory.id,
                docFormat: this.dataSource.selectedDocValue,
                language: this.dataSource.selectedlanguageValue,
                publicationTimestamp: this.dataSource.creationDate,
                s3FilePath: path || null
              }
              this.documentFile = { file: '', name: null }
              this.$refs.uploadRef.removeFile()

              this.addNewDocument(payload).then((res) => {
                if (res) {
                  this.onChangeModal(false)
                  this.getPmtDocumentsList()
                } else {
                  this.deleteFileFromS3(path)
                }
              })
            })
            .catch((error) => {
              console.error('Upload failed', error)
            })
            .finally(() => {
              this.dataSource.loadingUpload = false
              this.isLoading = false
            })
        } else {
          this.isLoading = false
          const payload = {
            companyId: this.dataSource?.selectedCompany.companyId,
            documentTitle: this.dataSource.documentTitle,
            documentUrl: this.dataSource.documentUrl,
            docCategoryId: this.dataSource.selectedDocCategory.id,
            docFormat: this.dataSource.selectedDocValue,
            language: this.dataSource.selectedlanguageValue,
            publicationTimestamp: this.dataSource.creationDate,
            s3FilePath: null
          }
          this.addNewDocument(payload).then((res) => {
            if (res) {
              this.onChangeModal(false)
              this.getPmtDocumentsList()
            }
          })
        }
      }
    },

    /** This method cancel uploaded file.
     * @public
     */
    handleCancelUpload(e) {
      this.documentFile = { file: '', name: '' }
      this.$refs.uploadRef.removeFile()
    },
    handleCancel() {
      this.$router.push('/publication-monitoring-tool/documents')
    }
  }
}
</script>

<style lang="scss" scoped>
.new-document-container {
  background: var(--primary);
  border-radius: 4px;

  .new-document {
    .columns {
      .column {
        .submit-button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          .actions {
            position: sticky;
            z-index: 10;
            margin-top: 25px;
            text-align: end;
            .button {
              height: 32px;
              border: none;
              font-size: 14px;
              width: 75px;
            }
            .button:nth-child(1) {
              margin-left: 5px;
              background-color: transparent;
              color: var(--secondary-text-color);
              border: 1px solid var(--primary-border-color);
              &:hover {
                border: 2px solid var(--primary-border-color);
                transition: border 0.3s ease-out;
              }
              &:active {
                background-color: #7f8fa466;
                transition: background-color 0.3s ease-out;
              }
            }
            .button:nth-child(2) {
              margin-left: 5px;
              background-color: var(--rr-download-file-background);
              color: #ffff;
              margin-left: 8px;
              &:active {
                background-color: var(--rr-download-file-background);
                transition: background-color 0.3s ease-out;
              }
            }
          }
        }
      }
    }
  }
  .required-error {
    color: red;
    position: absolute;
    font-size: 12px;
  }
  .required {
    color: red;
    padding: 2px;
    font-size: 17px;
  }
  .deleteBtn {
    cursor: pointer;
    color: red;
    .mdi-24px.mdi:before {
      font-size: 17px;
    }
  }

  .delete-icon {
    display: flex;
    gap: 8px;
    margin-top: 3px;
  }
}
</style>
